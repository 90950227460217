import { ComponentProps, ComponentType } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

export default function withUnauthProtection(Component: ComponentType) {
  return function WithAuthProtection(props: ComponentProps<ComponentType>) {
    const { user } = useAuth();

    const [params] = useSearchParams();

    if (user) {
      const redirect = params.get('redirect') || '/';

      return <Navigate to={redirect} replace />;
    }

    return <Component {...props} />;
  };
}
