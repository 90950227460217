import { ComponentProps, ComponentType } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

export default function withAuthProtection(Component: ComponentType) {
  return function WithAuthProtection(props: ComponentProps<ComponentType>) {
    const { user } = useAuth();
    const { pathname } = useLocation();

    if (!user) {
      const redirect = `/login?redirect=${pathname}`;

      return <Navigate to={redirect} replace />;
    }

    return <Component {...props} />;
  };
}
